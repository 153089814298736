import React, { FC, useEffect } from 'react';
import { useAppSelector, useResource } from '@tg/core/hooks';
import { Helmet } from 'react-helmet';
import { selectSessionUser } from '@tg/core/store/modules/session';
import type { Planner } from 'src/Routes/TimeOff/types';
import type { Payslip, Contract as ContractInterface } from 'src/types';
import { useDispatch } from 'react-redux';
import TimeOff from './components/TimeOff';
import Hello from '../../../tgcore/components/organisms/Dashboard/Cards/Hello';
import Support from '../../../tgcore/components/organisms/Dashboard/Cards/Support';
import Contract from './components/Contract';
import LatestPayslip from './components/LatestPayslip';
import { Item as SupportCardItem } from '../../../tgcore/components/organisms/Dashboard/Cards/Support/Support';

interface Props {
  contractId: string;
}

const EmployeeDashboard: FC<Props> = ({ contractId }) => {
  const user = useAppSelector(state => selectSessionUser(state));
  const dispatch = useDispatch();

  const defaultRole = user?.roles?.sort((a, b) => {
    const roleA = a.name.toUpperCase();
    const roleB = b.name.toUpperCase();
    if (roleA < roleB) {
      return -1;
    }
    if (roleA > roleB) {
      return 1;
    }
    return 0;
  });

  const getDefaultRole = defaultRole[0]?.id;

  if (getDefaultRole) {
    dispatch({
      type: 'ADD_ROLE_ID',
      payload: getDefaultRole,
    });
  }

  /**
   * Contract Card
   */
  const {
    data: contractData,
    isFetching: contractLoading,
    getResource: getContractData,
  } = useResource<ContractInterface>({
    url: `employees/contracts/${contractId}`,
  });

  /**
   * Time off Card
   */
  const currentYear = new Date().getFullYear();
  const {
    data: timeOffData,
    isFetching: timeOffLoading,
    getResource: getPlannerData,
  } = useResource<Planner>({
    url: `employees/contracts/${contractId}/planner/${currentYear}`,
  });

  /**
   * Payslip Card
   */
  const {
    data: payslipData,
    isFetching: payslipLoading,
    getResource: getPayslipData,
  } = useResource<Payslip[]>({
    url: `employees/contracts/${contractId}/payslips`,
  });

  /**
   * 'Support' card
   */
  const supportItems = [
    {
      title: 'Want Teamed in another language?',
      description:
        'Did you know that Teamed is available in French as well as English?',
      button: {
        to: '/settings?language=true',
        text: 'Update Language',
      },
    },
  ] as SupportCardItem[];

  /**
   * In order to show skeleton loaders, we render this page before we know 'contractId'
   * This fetches all the data once we know it
   */
  useEffect(() => {
    if (contractId) {
      getContractData({});
      getPlannerData({});
      getPayslipData({});
    }
  }, [contractId]);

  return (
    <>
      <Helmet title='Dashboard' />
      <div
        className='flex flex-col gap-6 md:grid'
        style={{ gridTemplateColumns: '2fr 1fr' }}
      >
        <div className='space-y-6'>
          <Hello user={user} />
          <TimeOff data={timeOffData} loading={!contractId || timeOffLoading} />
          <LatestPayslip
            payslip={payslipData && payslipData[0]}
            loading={payslipLoading}
          />
        </div>
        <div className='space-y-6'>
          {/* Profile */}
          <Contract
            data={contractData}
            loading={!contractId || contractLoading}
          />
          <Support items={supportItems} />
        </div>
      </div>
    </>
  );
};

export default EmployeeDashboard;
